<template>
   <form class="mt-3 col-md-4" @submit.prevent="submit" style="padding:0">
      <div class="form-floating mb-3">
         <input type="number" class="form-control" id="floatingInput" v-model="req.balance" placeholder="Balance">
         <label for="floatingInput">Requested Balance</label>
      </div>
      <div class="form-floating">
         <input type="text" class="form-control" id="floatingPassword" v-model="req.cid" placeholder="Company ID">
         <label for="floatingPassword">Company ID</label>
      </div>
      <button class="submit mt-3" type="submit" v-if="!loading">PROCESS</button>
      <button class="submit mt-3" type="submit" v-else>
         <div class="spinner-grow" role="status"></div>
      </button>
      <div class="error mt-2" v-if="error">{{ error }}</div>
   </form>
</template>
<script>
import axios from 'axios'
export default {
   data() {
      return {
         req: {},
         loading: false,
         error: ""
      }
   },
   methods: {
      async submit() {
         let vm = this
         if(!_.isEmpty(this.req.balance) && !_.isEmpty(this.req.cid)) {
            this.error = ''
            await axios.post('https://www.desertlinktours.com/api/admin/updateBalance', {
               reqbalance: vm.req.balance,
               CmpID: vm.req.cid
            }).then((response) => {
               // console.log(response)
            }).catch((error) => this.error = error)
         } else {
            return this.error = "Please fill in all the values"
         }
      }
   }
}
</script>
<style lang="scss" scoped>
.submit {
   width: 100%;
   height: 50px;
   border: .5px solid #212121;
   background: #212121;
   color: #fff;
   border-radius: 4px;
   transition: all 500ms ease-in-out;

   &:hover {
      background: #fff;
      color: #212121;
      border: .5px solid #212121;
   }
}

.error {
   width: 100%;
   background: #EF5350;
   color: #fff;
   text-align: center;
   padding: 5px 0;
   border-radius: 4px;
   text-transform: uppercase;
}
</style>