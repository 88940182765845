<template>
  <div class="table-responsive">
    <table class="table history-table table-hover">
      <thead class="history-head">
      <th scope="col">#</th>
      <th scope="col">Transaction ID</th>
      <th scope="col">Date Placed</th>
      <th scope="col">Req Amount</th>
      <th scope="col">Status</th>
      </thead>
      <tbody class="table-body">
      <tr v-for="(data, index) in history" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ data.TransactionID }}</td>
        <td>{{ data.TimePlaced.split("T")[0] }}</td>
        <td>{{ data.ReqBalance }}</td>
        <td style="color: #4CAF50">{{ data.Status }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Transactions.vue",
  data() {
    return {
      userid: {},
      history: {}
    };
  },
  mounted() {
    this.userid = JSON.parse(localStorage.getItem("agent"));
    this.getData();

  },
  methods: {
    getData() {
      this.$store.dispatch("account/balancehistory", { agentid: this.userid.Agtid }).then((response) => {
        this.items = response.data;
        this.history = response;
      }).catch((error) => console.log(error));

    }
  }
};
</script>

<style lang="scss" scoped>

.table-responsive {
  max-height: 500px;
  overflow-y: scroll;
}

.history-table {
  -webkit-box-shadow: 0px 3px 9px 0px rgb(231, 231, 231);
  -moz-box-shadow: 0px 3px 9px 0px rgb(236, 236, 236);
  box-shadow: 0px 3px 9px 0px rgb(238, 238, 238);
  cursor: pointer;

  .history-head {
    text-align: center;
    background: #212121;
    color: white;
    position: sticky;
    top: 0;
    z-index: 10;

    th {
      padding: 20px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      position: sticky;
      top: 0;
    }
  }


  td {
    text-align: center;
    background: #fff;
    padding: 40px 0;
  }

}

</style>
