<template>
  <div class="profile-container">
    <div class="container pt-5 mt-3">
      <div class="row d-flex justify-content-evenly">
        <div class="col-md-3 d-flex justify-content-center box">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <span class="title">Agent Profile</span>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
              <span class="resText">{{ user.AgtName }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 d-flex justify-content-center box">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <span class="title">Agent ID</span>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
              <span class="resText">{{ user.Agtid }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 d-flex justify-content-center box">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <span class="title">Agent Email</span>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
              <span>{{ user.AgtEmail }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 d-flex justify-content-center box">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <span class="title">Agent Address</span>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
              <span class="resText">{{ user.AgtAdd }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- ------------------------------------------------------------------------ -->
      <div class="row mt-5 d-flex justify-content-evenly flex-nowrap">
        <div class="col-md-3 d-flex justify-content-center box">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <span class="title">Company ID</span>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
              <span class="resText">{{ user.CmpID }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex justify-content-center box">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <span class="title">Company Name</span>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
              <span class="resText">{{ user.CmpName }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 d-flex justify-content-center box">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <span class="title">License No</span>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
              <span class="resText">{{ user.LcnNo }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 d-flex justify-content-center box">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <span class="title">Available Balance</span>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
              <span class="resText" style="color: #26A69A">AED {{ user.CmpBalance }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- ------------------------------------------------------------------------ -->
      <div class="row py-3 mt-5 topup-container">
        <div class="row d-flex justify-content-center">
          <div class="col-md-2 pt-2">Need a Top Up?</div>
          <div class="col-md-2 d-flex justify-content-center">
            <b-button class="topup" v-b-modal.modal-center>Top Up</b-button>
            <b-modal id="modal-center" centered title="Top Up" size="lg">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="companyName">Company Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="formGroupExampleInput"
                        :value="user.CmpName"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="agentid">Agent ID</label>
                      <input
                        type="text"
                        class="form-control"
                        id="agentid"
                        :value="user.Agtid"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="agentid">Current Balance</label>
                      <input
                        type="text"
                        class="form-control"
                        id="agentid"
                        :value="'AED ' + user.CmpBalance"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="newBalance">Request Balance</label>
                      <input
                        type="number"
                        class="form-control"
                        id="newBalance"
                        v-model="newBalance"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="block-container">
                      <div class="block-name">Name: DESERT LINK TOURISM L.L.C</div>
                      <div class="block-iban">IBAN: AE900240097524244294401</div>
                      <div class="block-ac">A/C No: 097524244294401</div>
                      <div class="block-bank">Bank: Dubai Islamic Bank</div>
                      <div class="block-branch">Branch: SZR</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h6 class="notice">IMPORTANT: Please transfer the money through bank after you have submitted your
                      request.</h6>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col" style="display: flex; justify-content: center">
                  <h6 class="msg" style="text-transform: uppercase" v-if="!isSuccess">{{ msg }}</h6>
                  <h6 class="msg" style="text-transform: uppercase; color: #66BB6A" v-if="isSuccess">{{ msg }}</h6>
                </div>
              </div>
              <template #modal-footer>
                <div class="w-100 footer-container">
                  <b-spinner type="grow" v-if="loading"></b-spinner>
                  <b-button
                    variant="primary"
                    size="md"
                    class="footer-submit"
                    @click="topup()"
                    v-if="!loading"
                    :disabled="disable"
                  >
                    Top Up
                  </b-button>
                </div>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
      <!-- ------------------------------------------------------------------------ -->
      <hr class="mt-5"/>
      <div class="row d-flex flex-column justify-content-center mb-0">
        <div class="col-xs-12 col-md-12 d-flex justify-content-center p0">
              <span class="recent-text">Recent Transactions</span>
            </div>
      </div>
      <hr class="mb-5" />
      <div class="table-container mt-3">
        <div class="row">
          <div class="col-xs-12 col-md-12 p-0">
            <transactions></transactions>
          </div>
        </div>
      </div>
    </div>
    <!-- ---------------------------------------------------------------------------------------- -->

  </div>
</template>
<script>
import UpdateBalance from "../components/Admin/updateBalance.vue";
import Transactions from "@/components/Transactions";

export default {
  data() {
    return {
      ub: false,
      user: {},
      msg: "",
      isSuccess: false,
      loading: false,
      disable: true,
      newBalance: Number,
      history: {}
    };
  },
  components: { UpdateBalance, Transactions },
  mounted() {
    this.user = this.$store.state.login.login.user;
  },
  updated() {
    if (this.newBalance.length != 0) {
      this.disable = false;
      this.msg = "";
    }
  },
  methods: {
    async topup() {
      this.loading = true;
      if (!this.newBalance) {
        this.loading = false;
        return this.msg = "Please fill in the TopUp Amount..";
      }

      var token = localStorage.getItem("access_token");
      if (this.$session.exists()) {
        let payload = {
          agentid: this.user.Agtid,
          company: this.user.CmpName,
          current: this.user.CmpBalance,
          balance: this.newBalance,
          token: token
        };
        await this.$store.dispatch("account/balance", payload).then((res) => {
          if (res.status === 200) {
            this.msg = "Payment requested successfully!";
            this.newBalance = "";
            this.isSuccess = true;
            this.loading = false;
            this.disable = true;
            setTimeout(() => {
              this.$bvModal.hide("modal-center");
            }, 1500);
            return;
          } else {
            this.msg = "There was some error while processing, Please try after some time.";
            this.newBalance = "";
            this.isSuccess = false;
            this.loading = false;
            this.disable = true;
          }
        });
      } else {
        this.error = "Please Login to Continue!";
        this.$router.push("/login");
      }
    }
  }
};
</script>

<style lang="scss">
#modal-center___BV_modal_header_ > button {
  border: none;
  background: none;
  background: #F44336;
  height: 30px;
  width: 30px;
  border-radius: 6px;
  color: white;
}
</style>

<style lang="scss" scoped>
.profile-container {
  width: 100%;
}

.sidebar-btn {
  background: #212121;
  padding: 5px 10px;

  &:hover {
    background: red;
  }

  &:focus, &:active {
    outline: none;
    border: none;
  }
}

.box {
  border-top: 4px solid #F44336;
  border-bottom: 2px solid #eaeaea;
  height: 150px;
  border-radius: 4px;
  padding: 16px 0;
  width: 20rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  transition: all 500ms ease-in-out;
  cursor: pointer;

  &:hover {
    transition: all 500ms ease-in-out;
    background: #F5F5F5;
    color: #212121;
  }

  .title {
    text-transform: uppercase;
    font-weight: 300;
  }

  .resText {
    font-weight: 600;
    font-family: 'Gilroy', sans-serif;
    text-transform: uppercase;
  }

  .account-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4% 0;
  }

  .account-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-evenly;
    -webkit-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    -moz-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    background-color: white;

    .form-group {
      margin: 10px 0;
    }

    .balance-wrap {
      padding: 20px 0;
      color: #f44336;
      font-weight: bold;
    }

    .topup {
      padding: 8px 24px;
      outline: none;
      position: relative;
      border-radius: 6px;
      border: none;
      top: -8px;
      background: #F44336;
      color: white;
      transition: all 500ms ease-in-out;

      &:hover {
        background: #212121;
      }
    }
  }
}

.notice {
  color: #F44336;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 12px;
  margin: 10px 0;
}

.msg {
  color: #F44336;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  margin: 10px 0;
}

.block-container {
  background: #F5F5F5;
  padding: 20px;
  margin: 20px 0;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  border-radius: 4px;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: center;

  .footer-submit {
    padding: 10px 24px;
  }
}

.topup-container {
  background: #212121;
  color: #eaeaea;
  border-radius: 4px;
  height: 70px;
  -webkit-box-shadow: inset 0px 5px 0px 0px #607D8B;
  -moz-box-shadow: inset 0px 5px 0px 0px #607D8B;
  box-shadow: inset 0px 5px 0px 0px #607D8B;

  .topup {
    padding: .5rem 2rem;
    border: 1px solid #fff;
    background: #212121;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;

    &:hover {
      background: #fff;
      color: #212121;
      transition: all 500ms ease-in-out;
    }
  }
}

.recent-text {
    font-size: 26px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }

</style>
